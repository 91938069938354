import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import routes from "./routes.js";
import { messages } from "@/Global/Components/KitForUI/PopMessages/messages.js";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// eslint-disable-next-line no-unused-vars
function redirectToLogin(next) {
  // Pass the original route to the login component
  next({ name: "Login" });
}

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  if (store.getters["layout/allowRedirection"]) {
    // Check if auth is required on this route
    // (including nested routes).
    const authRequired = routeTo.matched.some(
      (route) => route.meta.authRequired
    );

    // If auth isn't required for the route, just continue.
    if (!authRequired) {
      return next();
    }

    // remove active filters upon changing pages.
    store.dispatch("platform/no_data");

    // If auth is required and the user is logged in...
    if (store.getters["user/loggedIn"]) {
      next();
    } else {
      // If auth is required and the user is NOT currently logged in,
      // redirect to login.
      redirectToLogin(next);
    }
  } else {
    const continueEditting = await messages.unsavedChanges();

    if (continueEditting) {
      store.dispatch("layout/continue_editting", true);
    } else {
      store.dispatch("layout/set_allow_redirection", { redirection: true });

      store.dispatch("layout/continue_editting", false);

      next();
    }
  }
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have /some:deepnested/route and /some, /deep, and /nested have titles,
  // /nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) {
    return next();
  }

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
  next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  store.dispatch("layout/start_loading");
  // Create a beforeResolve hook, which fires whenever
  // beforeRouteEnter and beforeRouteUpdate would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in meta to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a beforeResolve hook is defined, call it with
        // the same arguments as the beforeEnter hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a beforeResolve hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

// router.afterEach((to, from) => {
//   store.dispatch("finish")
// })

export default router;
