import router from "@/router";

/**
 *
 * @param {Object} object The object to parse into a csv row string format.
 * @param {Boolean} useKey Wether to use the key or the value to parse into a row.
 * @returns {String} The row as a string
 */
const objectToCsvRow = (object, useKey = false) => {
  const seperator = ",";
  const returnCharater = "\n";
  return (
    Object.entries(object)
      .reduce((accumulator, currentValue) => {
        const [key, value] = currentValue;
        var element = useKey ? key : value;
        if (key === "product_description_detailled") {
          element = element.replace(/,/g, "");
        }
        return accumulator + element + seperator;
      }, "")
      .slice(0, -1) + returnCharater
  );
};

/**
 *
 * @param {Array<Object>} arrayOfObjects The json must contain the same keys in every object
 * @returns {String} The csv as a string
 */
export const parseArrayOfObjectsToCSV = (arrayOfObjects) => {
  // Extract headers
  const headers = Object.keys(arrayOfObjects[0]).join(",");
  // Extract rows
  const rows = arrayOfObjects
    .map((obj) => Object.values(obj).join(","))
    .join("\n");

  return headers + "\n" + rows;
};

/**
 * @param {Array<Object>} arrayOfObjects The json must contain the same keys in every object
 * @param {*} fileName The name of the file that will be constructed
 * @returns File Downloaded on your computer in .xlsx format
 */
export const parseArrayOfObjectToExcel = (arrayOfObjects, fileName) => {
  const XLSX = require("xlsx");
  const fileNameSplit = fileName.split(".")[0].split("_");
  const textCell = {
    t: "s",
  };
  const currencyCell = {
    t: "n",
    z: "#,##0.00",
  };
  const percentageCell = {
    t: "n",
    z: "0.00%",
  };
  const dateCell = {
    t: "d",
  };

  let binaryWS = XLSX.utils.json_to_sheet(arrayOfObjects, [
    { ...textCell },
    { ...textCell },
    { ...textCell },
    { ...dateCell },
    { ...textCell },
    { ...currencyCell, z: "0" },
    { ...currencyCell },
    { ...textCell },
    { ...percentageCell },
  ]);

  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(
    workbook,
    binaryWS,
    fileNameSplit[fileNameSplit.length - 1]
  );

  XLSX.writeFile(workbook, fileName);
};

export function breakdownRow(row) {
  let newObject = {};

  Object.keys(row).forEach((key) => {
    newObject = {
      ...newObject,
      [router.app.$t(`platform.database.${key}`)]: row[key] ? row[key] : "",
    };
  });

  return newObject;
}
