import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { state } from "./state";

import { overview } from "../Tabs/Overview/Store/index.js";
import { packageInformation } from "../Tabs/UploadInvestigation/Store/index.js";
import { myClaims } from "../Tabs/MyClaims/Store/index.js";

export const claimCenter = {
  state: {
    ...state,
    overview: overview.state,
    packageInformation: packageInformation.state,
    myClaims: myClaims.state,
  },
  mutations: {
    ...mutations,
    ...overview.mutations,
    ...packageInformation.mutations,
    ...myClaims.mutations,
  },
  getters: {
    ...getters,
    ...overview.getters,
    ...packageInformation.getters,
  },
  actions: {
    ...actions,
    ...overview.actions,
    ...packageInformation.actions,
    ...myClaims.actions,
  },
};
