export const actions = {
  individualMonthlyDataRefunds({ commit }, payload) {
    commit("INDIVIDUAL_MONTHLY_DATA_REFUNDS", payload);
  },
  totalMonthlyDataRefunds({ commit }, payload) {
    commit("TOTAL_MONTHLY_DATA_REFUNDS", payload);
  },
  setRefundsOverviewData({ commit }, payload) {
    commit("SET_REFUNDS_OVERVIEW_DATA", payload);
  },
  setPerCarrierRefundsGraphData({ commit }, payload) {
    commit("SET_PER_CARRIER_MONTHLY_REFUNDS_GRAPH_DATA", payload);
  },
  setTotalRefundsGraphData({ commit }, payload) {
    commit("SET_TOTAL_MONTHLY_REFUNDS_GRAPH_DATA", payload);
  },
  setRefundsDoughnutData({ commit }, payload) {
    commit("SET_REFUNDS_DOUGHNUT_DATA", payload);
  },
  refundsDoughnutTableData({ commit }, payload) {
    commit("REFUNDS_DOUGHNUT_TABLE_DATA", payload);
  },
};
