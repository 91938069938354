export const state = {
  generalData: null,
  lineChartData: {
    month: {
      total: null,
      individual: null,
    },
    week: {
      total: null,
      individual: null,
    },
  },
  doughnutData: { table: null },
};
