/**
 *
 * @param {String} name The name of the file as it should be downloaded
 * @param {Any} file The JS object representing the file to be downloaded
 * @example
 * const dataFormatedAsCSV = "column1,column2,\nvalue1,value2"
 * downloadFile("invoice_1234.csv", dataFormatedAsCSV)
 */
export const downloadFile = (name, file) => {
  // Create a Blob from the CSV String
  const blob = new Blob([file], { type: "text/csv;charset=utf-8;" });
  // Create a link element
  const link = document.createElement("a");
  // Set the URL as the Blob
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  // Set the download attribute with a filename
  link.setAttribute("download", name);
  // Append the link to the document and trigger the download
  document.body.appendChild(link);
  link.click();
  // Cleanup and remove the link
  document.body.removeChild(link);
};
