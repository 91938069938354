import store from "@/store";
import { fetchMembers } from "@/Platform/TeamMembers/Requests/index.js";
import { messages } from "@/Global/Components/KitForUI/PopMessages/messages.js";
import { userRequestedPasswordReset } from "@/Platform/ResetPassword/Requests/index.js";
import { packageInformationRequests } from "@/Platform/ClaimCenter/Tabs/UploadInvestigation/Requests";
import { defaultDateRange } from "../Global/Utils/dates";

const Login = () => import("@/Platform/Login/MainPage/index.vue");
const OnBoarding = () => import("@/Platform/OnBoarding/MainPage/index.vue");
const Register = () => import("@/Platform/Register/MainPage/index.vue");
const ForgotPassword = () =>
  import("@/Platform/ForgotPassword/MainPage/index.vue");
const ResetPassword = () =>
  import("@/Platform/ResetPassword/MainPage/index.vue");
const Home = () => import("@/Platform/Home/MainPage/index.vue");
const ClaimsOverview = () =>
  import("@/Platform/ClaimCenter/Tabs/Overview/MainPage/index.vue");
const InvestigationDetails = () =>
  import("@/Platform/ClaimCenter/Tabs/InvestigationDetails/MainPage/index.vue");
const ActionRequired = () =>
  import("@/Platform/ClaimCenter/Tabs/ActionRequired/MainPage/index.vue");
const UploadInvestigationDocumentation = () =>
  import(
    "@/Platform/ClaimCenter/Tabs/UploadInvestigation/Documentation/index.vue"
  );
const MyClaims = () =>
  import("@/Platform/ClaimCenter/Tabs/MyClaims/MainPage/index.vue");
const Profile = () => import("@/Platform/Profile/MainPage/index.vue");
const Team = () => import("@/Platform/TeamMembers/MainPage/index.vue");
const CarrierCredentials = () =>
  import("@/Platform/CarrierCredentials/MainPage/index.vue");
const BillingAndSubscription = () =>
  import("@/Platform/BillingAndSubscription/MainPage/index.vue");
const Invoices = () => import("@/Platform/Invoices/MainPage/index.vue");
const AdminPanel = () => import("@/Platform/AdminPanel/MainPage/index.vue");
const DataAgreement = () => import("@/Policies/landingDataPolicy.vue");
const TermsAndConditions = () => import("@/Policies/landingTerms.vue");
const NotFound = () => import("@/Platform/NotFound/MainPage/index.vue");

export default [
  {
    path: "/login",
    name: "Login",
    meta: {
      authRequired: false,
      title: "Login Page - Controls the Authorization of users",
    },
    component: Login,
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/register",
    meta: {
      authRequired: false,
      title: "Register Page - Control new users being authorized.",
    },
    name: "Register",
    component: Register,
  },
  {
    path: "/onBoarding",
    meta: {
      authRequired: false,
      title: "Register Page - Control new users being authorized.",
    },
    name: "OnBoarding Flow",
    component: OnBoarding,
  },
  {
    path: "/forgotPassword/request",
    name: "Password Request",
    component: ForgotPassword,
    meta: {
      title: "Forgot Password Page - Request to change your password.",
    },
  },
  {
    path: "/forgotPassword/reset",
    name: "Password Reset",
    component: ResetPassword,
    meta: {
      async beforeResolve(to, from, next) {
        if ("UID" in to.query) {
          const tokenCheck = await userRequestedPasswordReset(
            to.query.UID,
            next
          );

          if (tokenCheck.valid) {
            store.dispatch("user/userEmail", { email: tokenCheck.email });
            next();
          }
        } else {
          messages.accessDenied(next, "PasswordRequest");
        }
      },
      title: "Reset Password Page - Be able to change your password.",
    },
  },
  {
    path: "/home",
    meta: {
      authRequired: true,
      title:
        "Invoices Dashboard - Insights into your Monthly invoices, broken down so it is easliy digestable.",
    },
    name: "Home",
    component: Home,
  },
  {
    path: "/claimCenter/overview",
    meta: {
      authRequired: true,
      title:
        "Refunds Dashboard - Breakdown of the process, where we request, confirm and claim your refunds found by Lox.",
    },
    name: "Claim Center - Overview",
    component: ClaimsOverview,
  },
  {
    path: "/claimCenter/investigationDetails",
    meta: {
      authRequired: true,
      title:
        "Refunds Dashboard - Breakdown of the process, where we request, confirm and claim your refunds found by Lox.",
    },
    name: "Claim Center - Investigation Details",
    component: InvestigationDetails,
  },
  {
    path: "/claimCenter/actionRequired",
    meta: {
      authRequired: true,
      title:
        "Update Investigations - Complete needed actions to continue the claiming process.",
    },
    name: "Claim Center - Action Required",
    component: ActionRequired,
  },
  {
    path: "/claimCenter/documentation/uploadInvestigation",
    meta: {
      authRequired: false,
      title: "Upload Investigations - Upload supporting documents",
    },
    name: "Documentation - Upload Investigation",
    component: UploadInvestigationDocumentation,
  },
  {
    path: "/claimCenter/myClaims",
    meta: {
      authRequired: true,
      title:
        "Refunds Dashboard - Breakdown of the process, where we request, confirm and claim your refunds found by Lox.",
    },
    name: "Claim Center - My Claims",
    component: MyClaims,
  },
  {
    path: "/profile",
    meta: {
      authRequired: true,
      title: "Profile Page - Help users control their profile.",
    },
    name: "Profile",
    component: Profile,
  },
  {
    path: "/team",
    async beforeEnter(to, from, next) {
      if (store.getters["platform/teamMembers"] === null) {
        await fetchMembers();
        next();
      } else {
        next();
      }
    },
    meta: {
      authRequired: true,
      title: "Team Members - Manage your team.",
    },
    name: "Team Members",
    component: Team,
  },
  {
    path: "/carrier/information",
    meta: {
      authRequired: false,
      beforeResolve(to, from, next) {
        // console.log(authMethods.updateUserVerification, to, from);
        // If the user is already logged in
        if (
          !store.getters["user/isVerified"] &&
          store.getters["user/loggedIn"]
        ) {
          store._mutations["user/UPDATE_USER_VERIFICATION"];
          // Redirect to the home page instead
          // authMethods.updateUserVerification();
          next();
        } else if (
          store.getters["user/isVerified"] &&
          store.getters["user/loggedIn"]
        ) {
          next();
        } else {
          next({ name: "Login" });
        }
      },
      title:
        "Carrier Credentials Page - A area to control the credentials of you carriers in order for Lox Solution to retreive you Carrier Invoices.",
    },
    name: "Carrier Information",
    component: CarrierCredentials,
  },
  {
    path: "/billing",
    meta: {
      authRequired: true,
      title:
        "Billing and Invoices Page - Have access to the invoices created by Lox as well as maintaining your billing information.",
    },
    name: "Billing and Subscription",
    component: BillingAndSubscription,
  },
  {
    path: "/invoices",
    meta: {
      authRequired: true,
      title: "Invoices Page - Control all the invoices auditted.",
    },
    name: "Invoices",
    component: Invoices,
  },
  {
    path: "/adminPanel",
    meta: {
      authRequired: true,
      beforeResolve(from, to, next) {
        if (store.getters["user/isAdmin"]) {
          next();
        } else {
          messages.notAdmin(next);
        }
      },
      title: "Admin Page - Change the admin roles attached to all users.",
    },
    name: "Admin Panel",
    component: AdminPanel,
  },
  {
    path: "/data-agreement",
    meta: {
      title: "Data Processing Agreement - Lox Solution",
    },
    component: DataAgreement,
  },
  {
    path: "/terms-conditions",
    meta: {
      title: "Terms and Conditions - Lox Solution",
    },
    component: TermsAndConditions,
  },
  {
    path: "/404",
    component: NotFound,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];
