import Swal from "sweetalert2";
import {
  verifyEmail,
  register,
  emailVerified,
  sendSlackMessage,
} from "@/Platform/Register/Requests/index.js";
import store from "@/store";
import router from "@/router";
import i18n from "@/Global/Translations/i18n";

function showClaimMessage(text) {
  Swal.fire({
    title: i18n.global.t("message.claimNotCreated.title"),
    text,
    icon: "error",
  });
}

const contactUrl = "https://www.loxsolution.com/contactus";

export const messages = {
  emailFaild() {
    Swal.fire({
      title: i18n.global.t("message.emailError.title"),
      text: i18n.global.t("message.emailError.sub_text"),
      icon: "error",
    });
  },
  requiredFields() {
    Swal.fire({
      title: i18n.global.t("message.noInformation.title"),
      text: i18n.global.t("message.noInformation.text"),
      icon: "error",
    });
  },
  registrationFailed() {
    Swal.fire({
      title: i18n.global.t("message.registrationFailure.title"),
      text: i18n.global.t("message.registrationFailure.text"),
      showConfirmButton: true,
      confirmButtonText: i18n.global.t("message.loginFailure.confirmButton"),
      showCancelButton: true,
      cancelButtonText: i18n.global.t("platform.general.close"),
      icon: "error",
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(contactUrl, "_blank");
      } else {
        Swal.close();
      }
    });
  },
  loginFailed() {
    Swal.fire({
      title: i18n.global.t("message.loginFailure.title"),
      text: i18n.global.t("message.loginFailure.text"),
      showConfirmButton: true,
      confirmButtonText: i18n.global.t("message.loginFailure.confirmButton"),
      showCancelButton: true,
      cancelButtonText: i18n.global.t("platform.general.close"),
      icon: "error",
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(contactUrl, "_blank");
      } else {
        Swal.close();
      }
    });
  },
  passwordAuthenticationNotEnabled() {
    Swal.fire({
      title: i18n.global.t("message.passwordAuthenticationNotEnabled.title"),
      text: i18n.global.t("message.passwordAuthenticationNotEnabled.text"),
      icon: "error",
    });
  },
  incorrectFileType(types) {
    Swal.fire({
      title: i18n.global.t("message.wrongFileType.title"),
      text: i18n.global.t("message.wrongFileType.text", { types }),
      icon: "error",
      heightAuto: false,
    });
  },
  incorrectHeaders() {
    Swal.fire({
      title: i18n.global.t("message.incorrectHeaders.title"),
      text: i18n.global.t("message.incorrectHeaders.text"),
      icon: "error",
    });
  },
  confirmDataLoss() {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: i18n.global.t(
          "platform.refunds.investigations.previousStep.warning"
        ),
        text: i18n.global.t(
          "platform.refunds.investigations.previousStep.text"
        ),
        confirmButtonText: i18n.global.t(
          "platform.refunds.investigations.previousStep.confirmButton"
        ),
        showCancelButton: true,
        cancelButtonText: i18n.global.t("platform.general.close"),
      }).then((result) => {
        resolve(result.isConfirmed);
      });
    });
  },
  noValidTrackingNumbers() {
    Swal.fire({
      title: "No Valid Tracking Numbers",
      text: "The tracking numbers given are not valid for this feature. Please however add your tracking numbers via the 'Start an Investigation' page.",
      confirmButtonText: "Start an Investigation",
      showCancelButton: true,
      cancelButtonText: "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        router.push({
          path: "/claimCenter/myClaims",
          query: { start: true },
        });
      }
    });
  },
  claimDataNotUploaded() {
    showClaimMessage(i18n.global.t("message.claimNotCreated.data.text"));
  },
  claimDocumentsNotUploaded() {
    showClaimMessage(i18n.global.t("message.claimNotCreated.documents.text"));
  },
  uploadedFilesSuccessfully({ filesUploaded, filesErrored }) {
    const uploads = filesUploaded.length;
    const errors = filesErrored !== "None" ? filesErrored.length : 0;
    const total = uploads + errors;

    Swal.fire({
      title: i18n.global.t("platform.arrayColumns.uploaded"),
      text: `${uploads} / ${total} ${i18n.global.t(
        "platform.invoices.uploaded"
      )}`,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        if (filesErrored !== "None") {
          this.failedToUploadFile(filesErrored);
        }
      }
    });
  },
  confirmAmount(amount) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: i18n.global.t("message.confirmAmount.title"),
        text: i18n.global.t("message.confirmAmount.text", { amount }),
        confirmButtonText: i18n.global.t("message.confirmAmount.confirmButton"),
        showCancelButton: true,
        cancelButtonText: i18n.global.t("message.confirmAmount.cancelButton"),
        icon: "warning",
      }).then((result) => resolve(result.isConfirmed));
    });
  },
  exclusiveHigherThanInclusive() {
    Swal.fire({
      title: i18n.global.t("message.wrongInclusiveAmount.title"),
      test: i18n.global.t("message.wrongInclusiveAmount.text"),
      icon: "error",
    });
  },
  bulkUploadSuccess(successRate, fromEmail) {
    Swal.fire({
      title: "Document Uploaded!",
      text: `Successfully injected ${successRate} rows within the uploaded template.`,
      icon: "success",
    }).then(() => {
      if (fromEmail) {
        router.push("/login");
      }
    });
  },
  incorrectDateFormat() {
    Swal.fire({
      title: "Oops!",
      text:
        "We have found that you uploaded this document with incorrect formats for your dates. Please find the correct format in the header of the cell or in the cell body itself." +
        "\n\n" +
        "Please edit the document and upload it again." +
        "\n\n" +
        "If you are still having issues please contact our support team at customersuccess@loxsolution.com",
      icon: "error",
    });
  },
  failedToUploadFile(files) {
    Swal.fire({
      title: i18n.global.t("message.genericError[0]"),
      text: `The follwing files failed to upload:\n\n${files}\n\n either try upload them again or contact our success team.\n\ncustomersuccess@loxsolution.com`,
      icon: "error",
    });
  },
  generalError() {
    Swal.fire(
      i18n.global.t("message.genericError[0]"),
      i18n.global.t("message.genericError[1]"),
      "error"
    );
  },
  generalSuccess() {
    Swal.fire("Saved!", "Your changes has been well saved.", "success");
  },
  accountNotProcessed() {
    Swal.fire({
      title: i18n.global.t("message.loginErrors.title"),
      text: i18n.global.t("message.loginErrors.status_403"),
      icon: "error",
    });
  },
  addCredentials() {
    Swal.fire({
      title: i18n.global.t("message.carrierCredentials.title"),
      text: i18n.global.t("message.carrierCredentials.text"),
      showCancelButton: true,
      allowOutsideClick: false,
      cancelButtonText: i18n.global.t("platform.general.close"),
      confirmButtonText: i18n.global.t(
        "message.carrierCredentials.confirmButton"
      ),
    }).then((result) => {
      if (result.isConfirmed) {
        router.push("/carrier/information");
      } else {
        Swal.close();
      }
    });
  },
  credentialsNotFound() {
    Swal.fire({
      title: i18n.global.t("message.loginErrors.title"),
      text: i18n.global.t("message.loginErrors.status_401"),
      icon: "error",
    });
  },
  verifyEmailSuccess(form, registerObject) {
    Swal.fire({
      title: i18n.global.t("message.verifyEmail.title"),
      text: i18n.global.t("message.verifyEmail.success"),
      icon: "success",
    }).then(() => {
      store.dispatch("layout/start_loading");
      register(form, registerObject.registerPage, registerObject.setEmptyForm);
    });
  },
  verifyEmailFailure() {
    Swal.fire({
      title: i18n.global.t("message.uploadError[0]"),
      text: i18n.global.t("message.verifyEmail.error"),
      icon: "error",
    });
  },
  emailExists() {
    Swal.fire({
      title: i18n.global.t("message.verifyEmail.emailExsists[0]"),
      text: i18n.global.t("message.verifyEmail.emailExsists[1]"),
      icon: "error",
    });
  },
  emailNotFound() {
    Swal.fire({
      title: i18n.global.t("message.uploadError[0]"),
      text: i18n.global.t("message.verifyEmail.emailNotFound"),
      icon: "error",
    });
  },
  codeValidator(form, registerObject) {
    Swal.fire({
      title: i18n.global.t("message.registerComplete.info[0]"),
      text: i18n.global.t("message.registerComplete.info[1]"),
      showCancelButton: true,
      allowOutsideClick: false,
      cancelButtonText: i18n.global.t("message.registerComplete.cancel"),
      confirmButtonText: i18n.global.t("message.registerComplete.confirm"),
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: i18n.global.t("message.registerComplete.info[0]"),
          text: i18n.global.t("message.registerComplete.info[2]"),
          showCancelButton: true,
          input: "text",
          inputValue: "00000000",
          allowOutsideClick: false,
          cancelButtonText: i18n.global.t("cta.back"),
          inputValidator(value) {
            if (!value) {
              return "You need to enter a valid code!";
            } else {
              emailVerified(value, form, registerObject);
            }
          },
          icon: "success",
        });
      } else if (result.isDismissed) {
        messages.manualCode(form);
      }
    });
  },
  manualCode(form) {
    Swal.fire({
      title: i18n.global.t("messages.noCodeError.title"),
      text: i18n.global.t("messages.noCodeError.text"),
      icon: "info",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        sendSlackMessage(form);
      }
    });
  },
  retryCode(form, registerObject) {
    Swal.fire({
      title: i18n.global.t("message.uploadError[0]"),
      text: i18n.global.t("message.verifyEmail.error"),
      icon: "error",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: i18n.global.t("cta.reSend"),
      cancelButtonText: i18n.global.t("cta.back"),
    }).then((result) => {
      if (result.isConfirmed) {
        verifyEmail(form, router.app.$i18n.locale, registerObject);
      }
    });
  },
  informCompany(setNewForm) {
    Swal.fire({
      title: i18n.global.t("message.companyExsists.title"),
      text: i18n.global.t("message.companyExsists.textBlock"),
      icon: "warning",
      preConfirm() {
        setNewForm();
      },
    });
  },
  oneInvoicePerTrackingNumber() {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: i18n.global.t("message.oneInvoice.title"),
        text: i18n.global.t("message.oneInvoice.text"),
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: i18n.global.t("message.oneInvoice.confirm"),
        cancelButtonText: i18n.global.t("message.oneInvoice.cancel"),
        icon: "warning",
      }).then((result) => {
        resolve(result.isConfirmed);

        Swal.close();
      });
    });
  },
  unsavedChanges() {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: i18n.global.t("message.unsavedChanges.title"),
        text: i18n.global.t("message.unsavedChanges.text"),
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: i18n.global.t("message.unsavedChanges.confirm"),
        cancelButtonText: i18n.global.t("message.unsavedChanges.cancel"),
        icon: "warning",
      }).then((result) => {
        resolve(result.isConfirmed);

        Swal.close();
      });
    });
  },
  selectCredential() {
    Swal.fire({
      title: "No Credential Selected!",
      text: "Please select a credential in order for the email to have the correct information.",
      icon: "error",
    });
  },
  wrongCompanySelected() {
    Swal.fire({
      title: "Oops!",
      text: "You clicked the wrong Send Button for the selected Company credentials, please be sure to click the correct one.",
      icon: "error",
    });
  },
  confirmSendout(confirmed, params) {
    Swal.fire({
      title: "Are you sure?",
      text: `You're about to send mails to ${
        params.company
      } regarding their selected credentials not working. Are you sure, these emails are correct? ${params.emails.join(
        ", "
      )}`,
      confirmButtonText: "Yes, I'm sure!",
      showCancelButton: true,
      cancelButtonText: "No, there's a mistake!",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        confirmed(params);
      } else {
        Swal.close();
      }
    });
  },
  confirmEmail(email) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: email,
        text: "This email wasn't found in our Database. Please be aware you are sharing personal data, so please be sure this email is correct.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm Email",
        cancelButtonText: "Mistake Made",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          resolve("Confirmed");
        } else {
          resolve("Rejected");
        }
      });
    });
  },
  confirmEmailSendOut(emails, sendOut, tableRow) {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to send emails to the selected users.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm Send Out",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        sendOut(emails, tableRow);
      }
    });
  },
  forgotPasswordSent() {
    Swal.fire({
      title: i18n.global.t("message.mailSent.title"),
      text: i18n.global.t("message.mailSent.text"),
      icon: "success",
      confirmButtonColor: "#ff5b6d",
    });
  },
  lockedDashboard() {
    Swal.fire({
      title: i18n.global.t("message.lockedDashboard.title"),
      text: i18n.global.t("message.lockedDashboard.text"),
      icon: "info",
    });
  },
  lockedProducts() {
    Swal.fire({
      title: i18n.global.t("message.lockedProducts.title"),
      text: i18n.global.t("message.lockedProducts.text"),
      icon: "info",
    });
  },
  noData() {
    Swal.fire({
      title: i18n.global.t("message.noData.title"),
      text: i18n.global.t("message.noData.text"),
      icon: "error",
    });
  },
  noDataRetrieved() {
    Swal.fire({
      title: i18n.global.t("message.noDataRetrieved.title"),
      text: i18n.global.t("message.noDataRetrieved.title"),
      icon: "error",
    });
  },
  noDataUpload() {
    Swal.fire({
      title: i18n.global.t("message.noDataUploadError.title"),
      text: i18n.global.t("message.noDataUploadError.text"),
      icon: "error",
    });
  },
  profileUpdated() {
    Swal.fire({
      title: i18n.global.t("message.profileUpdate.title"),
      text: i18n.global.t("message.profileUpdate.text"),
      icon: "success",
    });
  },
  profileUpdateFailure() {
    Swal.fire({
      title: i18n.global.t("message.profileUpdateFailure.title"),
      text: i18n.global.t("message.profileUpdateFailure.text"),
      icon: "error",
    });
  },
  passwordUpdated() {
    Swal.fire({
      title: i18n.global.t("message.passwordUpdated[0]"),
      text: i18n.global.t("message.passwordUpdated[1]"),
      icon: "success",
    });
  },
  passwordUpdateError() {
    Swal.fire({
      title: i18n.global.t("message.passwordError[0]"),
      text: i18n.global.t("message.passwordError[1]"),
      icon: "error",
    });
  },
  successfulProcess() {
    Swal.fire({
      title: "Saved!",
      text: "Your company has been changed.",
      icon: "success",
    });
  },
  successAdminRegister() {
    Swal.fire({
      title: "User Added!",
      icon: "success",
    });
  },
  memberSaved(user) {
    Swal.fire({
      title: "Invitation Sent!",
      text: `${user.email_address} ${i18n.global.t(
        "message.teamMemberAdded[0]"
      )}`,
      icon: "success",
    });
  },
  memberNotSaved(user) {
    Swal.fire({
      title: "Invitation Failed!",
      text: `${user.email_address} ${i18n.global.t("message.notAdded.title")}`,
      icon: "error",
    });
  },
  memberDeleted() {
    Swal.fire({
      title: i18n.global.t("message.memberDeleted[0]"),
      text:
        localStorage.getItem("locale") == "en"
          ? i18n.global.t("message.memberDeleted[1]")
          : "",
      icon: "success",
    });
  },
  notAdminAccess() {
    Swal.fire({
      title: i18n.global.t("message.clientAccess.title"),
      text: i18n.global.t("message.clientAccess.text"),
      icon: "error",
    });
  },
  async credentialsUploaded() {
    Swal.fire({
      title: i18n.global.t("message.credentialsUploaded[0]"),
      text: i18n.global.t("message.credentialsUploaded[1]"),
      icon: "success",
    });
  },
  credentialsUpdated() {
    Swal.fire({
      title: i18n.global.t("message.credentialsUpdated[0]"),
      text: i18n.global.t("message.credentialsUpdated[1]"),
      icon: "success",
    });
  },
  credentialsDeleted() {
    Swal.fire({
      title: i18n.global.t("message.credentialsDeleted[0]"),
      text: i18n.global.t("message.credentialsDeleted[1]"),
      icon: "success",
    });
  },
  confirmDelete: {
    general(item, deleteFunction) {
      Swal.fire({
        title: i18n.global.t("message.confirmDelete[0]"),
        text: i18n.global.t("message.confirmDelete[1]"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: i18n.global.t("message.confirmDelete[2]"),
      }).then((result) => {
        if (result.value) {
          deleteFunction(item);
        }
      });
    },
    invoice(deleteFunction, params) {
      Swal.fire({
        title: "Beware",
        text: "You are about to delete a companies invoice. Please make sure you are 100% right.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5b6d",
        confirmButtonText: "Delete",
        cancelButtonColor: "#b9c0c6",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteFunction(params.document);
        } else {
          Swal.close();
        }
      });
    },
  },
  // logout() {
  //   Swal.fire({
  //     title: i18n.global.t("platform.topbar.satisfaction"),
  //     icon: "question",
  //     html: i18n.global.t("platform.topbar.satisfaction_text"),
  //     showConfirmButton: true,
  //     showDenyButton: true,
  //     showCancelButton: true,
  //     confirmButtonText:
  //       "<svg style='width:24px;height:24px' viewBox='0 0 24 24'><path fill='#fff' d='M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.5,8C16.3,8 17,8.7 17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M12,14C13.75,14 15.29,14.72 16.19,15.81L14.77,17.23C14.32,16.5 13.25,16 12,16C10.75,16 9.68,16.5 9.23,17.23L7.81,15.81C8.71,14.72 10.25,14 12,14Z' /></svg>",
  //     denyButtonText:
  //       "<svg style='width:24px;height:24px' viewBox='0 0 24 24'><path fill='#fff' d='M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M8.5,11A1.5,1.5 0 0,1 7,9.5A1.5,1.5 0 0,1 8.5,8A1.5,1.5 0 0,1 10,9.5A1.5,1.5 0 0,1 8.5,11M17,9.5A1.5,1.5 0 0,1 15.5,11A1.5,1.5 0 0,1 14,9.5A1.5,1.5 0 0,1 15.5,8A1.5,1.5 0 0,1 17,9.5M16,14V16H8V14H16Z' /></svg>",
  //     cancelButtonText:
  //       "<svg style='width:24px;height:24px' viewBox='0 0 24 24'><path fill='#fff' d='M12,17.5C14.33,17.5 16.3,16.04 17.11,14H6.89C7.69,16.04 9.67,17.5 12,17.5M8.5,11A1.5,1.5 0 0,0 10,9.5A1.5,1.5 0 0,0 8.5,8A1.5,1.5 0 0,0 7,9.5A1.5,1.5 0 0,0 8.5,11M15.5,11A1.5,1.5 0 0,0 17,9.5A1.5,1.5 0 0,0 15.5,8A1.5,1.5 0 0,0 14,9.5A1.5,1.5 0 0,0 15.5,11M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' /></svg>",
  //     customClass: {
  //       confirmButton: "swal-confirm-sad",
  //       cancelButton: "swal-cancel-happy",
  //       denyButton: "swal-deny-meh",
  //     },
  //     focusConfirm: false,
  //     allowOutsideClick: false,
  //   }).then((result) => {
  //     let session_score = 0;

  //     if (result.isConfirmed) {
  //       session_score = 30;
  //     } else if (result.isDenied) {
  //       session_score = 70;
  //     } else {
  //       session_score = 90;
  //     }

  //     accountRequests.documentSatisfaction(session_score);
  //   });
  // },
  timer() {
    let timerInterval;
    Swal.fire({
      title: i18n.global.t("platform.invoices.popup"),
      timerProgressBar: true,
      timer: 4000,
      willOpen() {
        Swal.showLoading();
      },
      didClose() {
        clearInterval(timerInterval);
      },
      icon: "success",
    });
  },
  downloadTimer() {
    let timerInterval;
    Swal.fire({
      title: i18n.global.t("platform.general.downloadCSV"),
      timerProgressBar: true,
      allowOutsideClick: false,
      timer: 20000,
      willOpen() {
        Swal.showLoading();
      },
      didClose() {
        clearInterval(timerInterval);
      },
      icon: "info",
    });
  },
  stopTimer() {
    Swal.close();
  },
  generalDeleted() {
    Swal.fire({
      title: "Document Deleted!",
      icon: "success",
      confirmButtonColor: "#34c38f",
    });
  },
  cancelApi(translations, disconnectAPI) {
    Swal.fire({
      ...translations,
      showCancelButton: true,
      customClass: {
        title: "modal-title",
        htmlContainer: "modal-text",
        confirmButton: "modal-confirm",
        cancelButton: "modal-cancel",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        disconnectAPI();
      }
    });
  },
  manualUpload(translations) {
    Swal.fire(translations);
  },
  failedManualUpload() {
    Swal.fire({
      title: i18n.global.t("message.uploadFailed.title"),
      text: i18n.global.t("message.uploadFailed.text"),
      icon: "error",
    });
  },
  messageSent() {
    Swal.fire({
      title: i18n.global.t("platform.team.sent"),
      text: i18n.global.t("message.messageSent"),
      icon: "success",
    });
  },
  messageNotSent() {
    Swal.fire({
      title: i18n.global.t("message.uploadFailed.title"),
      text: i18n.global.t("message.messageNotSent"),
      icon: "success",
    });
  },
  failedTemplateDownload() {
    Swal.fire({
      title: i18n.global.t("message.uploadFailed.title"),
      text: i18n.global.t("message.templateFailed"),
      icon: "error",
    });
  },
  noDataToDownload() {
    Swal.fire({
      title: "No available data to download",
      text: "There are no packages found that are needing support documents",
    });
  },
  apiConnectionFailed(connection) {
    Swal.fire({
      title: i18n.global.t("platform.refunds.apiFailed.title"),
      text: i18n.global.t(`platform.refunds.apiFailed.${connection}`),
      icon: "error",
    });
  },
  apiConfigSent() {
    Swal.fire({
      title: i18n.global.t("message.api.success.title"),
      text: i18n.global.t("message.api.success.text"),
      icons: "success",
    });
  },
  apiDisconnected() {
    Swal.fire({
      title: i18n.global.t("message.api.disconnected.title"),
      text: i18n.global.t("message.api.disconnected.text"),
    });
  },
  apiConfigFailed() {
    Swal.fire({
      title: i18n.global.t("message.verifyEmail.emailExsists[0]"),
      text: i18n.global.t("message.api.failed.text"),
      icon: "error",
    });
  },
  notAdmin(next) {
    Swal.fire({
      title: i18n.global.t("message.notAdmin.title"),
      text: i18n.global.t("message.notAdmin.text"),
      icon: "error",
      allowOutsideClick: false,
    }).then(() => {
      next({ name: "Home" });
    });
  },
  accessDenied(next, location) {
    Swal.fire({
      title: i18n.global.t("message.accessDenied.title"),
      text: i18n.global.t(`message.accessDenied.${location}.text`),
      icon: "error",
      allowOutsideClick: false,
    }).then(() => {
      next({ name: location });
    });
  },
  duplicateFile(translations, file, removeFile) {
    Swal.fire({
      title: translations.title,
      text: file.name + translations.text,
      icon: "error",
    }).then(() => {
      removeFile(file);
    });
  },
  async overwriteClaim(processOverwrite) {
    await Swal.fire({
      title: i18n.global.t("message.overwriteClaim.title"),
      text: i18n.global.t("message.overwriteClaim.text"),
      showCancelButton: true,
      confirmButtonText: i18n.global.t("cta.yes"),
      cancelButtonText: i18n.global.t("cta.no"),
      icon: "warning",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        processOverwrite();
      }
    });
  },
  successfulClaim() {
    Swal.fire({
      title: i18n.global.t("message.api.success.title"),
      text: i18n.global.t("message.successfulClaim.text"),
    });
  },
  investigationHistoryAction() {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: i18n.global.t("message.claimHistoryActionReceived.title"),
        text: i18n.global.t("message.claimHistoryActionReceived.text"),
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          resolve(true);
        }
      });
    });
  },
  noData() {
    Swal.fire({
      title: i18n.global.t("message.noData.title"),
      text: i18n.global.t("message.noData.text"),
      icon: "warning",
    });
  },
  messageSent() {
    Swal.fire({
      title: i18n.global.t("message.requestSent"),
      text: i18n.global.t("message.messageSent"),
    });
  },
  trackingNumberExists(trackingNumber, clearTracking) {
    Swal.fire({
      title: "Oops!",
      text: `We already have a registered claim in our database with this tracking number: ${trackingNumber}`,
      icon: "error",
    }).then((result) => {
      if (result.isConfirmed) {
        clearTracking();
      }
    });
  },
  captchaTriggered() {
    Swal.fire({
      title: "Oops!",
      text: "We have found some suspicious behavior with your request please confirm youe email below to continue.",
      icon: "error",
    });
  },
  emailsSent() {
    Swal.fire({
      title: "Email Send Out Success",
      text: "The emails have been sent out. Please check the table below for updates to come.",
      icon: "success",
    });
  },
  emailsFailed() {
    Swal.fire({
      title: "Email Send Out Failed",
      text: "The emails haven't been sent out. Please check with a developer on the issue.",
      icon: "error",
    });
  },
  reAuthenticateUser() {
    Swal.fire({
      title: "Oops!",
      text: "It seems there might be some malicious behavior with your request. Please login again in order to authenticate yourself again.",
      icon: "warning",
      confirmButtonText: "Login Page",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        router.push("/login");
      }
    });
  },
};
