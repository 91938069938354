export const mutations = {
  INDIVIDUAL_MONTHLY_DATA_REFUNDS(state, payload) {
    state.claimCenter = {
      ...state.claimCenter,
      overview: {
        ...state.claimCenter.overview,
        lineChartData: {
          ...state.claimCenter.overview.lineChartData,
          month: {
            ...state.claimCenter.overview.lineChartData.month,
            individual: payload,
          },
        },
      },
    };
  },
  TOTAL_MONTHLY_DATA_REFUNDS(state, payload) {
    state.claimCenter = {
      ...state.claimCenter,
      overview: {
        ...state.claimCenter.overview,
        lineChartData: {
          ...state.claimCenter.overview.lineChartData,
          month: {
            ...state.claimCenter.overview.lineChartData.month,
            total: payload,
          },
        },
      },
    };
  },
  SET_REFUNDS_OVERVIEW_DATA(state, payload) {
    state.claimCenter = {
      ...state.claimCenter,
      overview: {
        ...state.claimCenter.overview,
        generalData: {
          ...state.claimCenter.overview.generalData,
          ...payload.general,
        },
      },
    };
  },
  SET_PER_CARRIER_MONTHLY_REFUNDS_GRAPH_DATA(state, payload) {
    state.claimCenter = {
      ...state.claimCenter,
      overview: {
        ...state.claimCenter.overview,
        generalData: {
          ...state.claimCenter.overview.generalData,
          carrierRefunds: payload,
        },
      },
    };
  },
  SET_TOTAL_MONTHLY_REFUNDS_GRAPH_DATA(state, payload) {
    state.claimCenter = {
      ...state.claimCenter,
      overview: {
        ...state.claimCenter.overview,
        lineChartData: {
          ...state.claimCenter.overview.lineChartData,
          month: {
            ...state.claimCenter.overview.lineChartData.month,
            total: { ...payload },
          },
        },
      },
    };
  },
  SET_REFUNDS_DOUGHNUT_DATA(state, payload) {
    state.claimCenter = {
      ...state.claimCenter,
      overview: {
        ...state.claimCenter.overview,
        doughnutData: {
          ...state.claimCenter.overview.doughnutData,
          ...payload,
        },
      },
    };
  },
  REFUNDS_DOUGHNUT_TABLE_DATA(state, payload) {
    state.claimCenter = {
      ...state.claimCenter,
      overview: {
        ...state.claimCenter.overview,
        doughnutData: {
          ...state.claimCenter.overview.doughnutData,
          table: payload,
        },
      },
    };
  },
};
