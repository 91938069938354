import axios from "axios";
import store from "@/store";
import { messages } from "@/Global/Components/KitForUI/PopMessages/messages.js";
import {
  parseArrayOfObjectsToCSV,
  parseArrayOfObjectToExcel,
} from "@/Global/Utils/parsers.js";
import { downloadFile } from "@/Global/Utils/downloads.js";

/**
 * Request made to the Lox server with the
 * broken down file information.
 * @param {{ headers: Array<string>, information: Array<{}>}} fileInformation
 * The file information to be validated
 * @returns The file information validated and ready to insert into the table
 */
export function validateData(fileInformation) {
  return new Promise(function (resolve, reject) {
    const { token } = store.getters["user/userInfo"];

    axios
      .post(
        "/api/private/company/investigations/upload/validate",
        { fileInformation },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
/**
 * Function that sends the validate rows to the server to be
 * stored in the ClientInvoicesData Table in BigQuery.
 * @param {Array<{}>} information
 * The validated rows to insert
 * @returns If the insertion was successful or not
 */
export function saveFileInformation(information) {
  return new Promise(function (resolve, reject) {
    const { token } = store.getters["user/userInfo"];

    axios
      .post(
        "/api/private/company/investigations/upload/save",
        { information: JSON.stringify(information) },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        resolve({ status: 200, data: response.data });
      })
      .catch((error) => {
        resolve({ status: 400 });
      });
  });
}

export function updateInvestigationInformation(params, filters) {
  return new Promise(function (resolve, reject) {
    const { token } = store.getters["user/userInfo"];

    axios
      .post(
        "/api/private/company/refunds/actionRequired/upload/packageInformation",
        { claimData: params },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(async () => {
        await packageInformationRequests(filters, true);

        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function editInvestigationInformation(params, originalData) {
  return new Promise(function (resolve, reject) {
    const { token } = store.getters["user/userInfo"];

    axios
      .post(
        "/api/private/company/investigations/edit",
        {
          investigation: params,
          original_information: originalData,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function packageInformationRequests(filters, fetchAfterUpdate = false) {
  return new Promise(async function (resolve, reject) {
    const token = store.state.user.token;

    axios
      .get("/api/private/company/refunds/actionRequired/packages", {
        params: {
          page: store.getters["platform/packageInformation"].pageNumber,
          filters,
          fetchAfterUpdate,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        store.dispatch("platform/setLostPackages", {
          packages: response.data.stats,
        });

        resolve(response.data);
      })
      .catch((error) => {
        console.error(error.message);
        messages.generalError();
        reject(error);
      });
  });
}

export function fetchUploadTemplate(type, carrier) {
  return new Promise(function (resolve, reject) {
    const token = store.getters["user/userInfo"].token;

    axios
      .get("/api/private/invoices/data_needed/template", {
        params: {
          carrier,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let file;
        const fileName = `${store.getters["user/company"]}_investigation_support_document${type}`;

        if (!response.data.template.length) {
          resolve(false);
        } else if (type === ".csv") {
          file = parseArrayOfObjectsToCSV(response.data.template);

          downloadFile(fileName, file);
        } else if (type === ".xlsx") {
          file = parseArrayOfObjectToExcel(response.data.template, fileName);
        }

        resolve(true);
      })
      .catch((error) => {
        messages.failedTemplateDownload();
        console.error(error);
        reject(error);
      });
  });
}
