export const getters = {
  overviewData(state) {
    return state.claimCenter.overview.generalData;
  },
  refundsLineGraphData(state) {
    const chartData = state.claimCenter.overview.generalData.carrierRefunds;

    return {
      labels: chartData.labels,
      datasets: state.toggle
        ? chartData.datasetsPerCarrier
        : chartData.datasetsTotal,
    };
  },
  refundsDoughnutGraphData(state) {
    return state.claimCenter.overview.doughnutData;
  },
};
