import Moment from "moment";

const moment = Moment().locale(localStorage.getItem("locale"));
const year = moment.get("year");
const month = moment.get("month") + 1;
const endingDate = moment.endOf("month").format("DD");

export let defaultDateRange = {
  standard: {
    start: Moment(`${year}-${month}-01`, "YYYY-MM-DD")
      .subtract(12, "months")
      .format("YYYY-MM-DD"),
    end: Moment().format("YYYY-MM-DD"),
    months: 12,
    custom: false,
  },
  last_30_days: {
    start: Moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
    end: Moment().format("YYYY-MM-DD"),
    months: 1,
    custom: false,
  },
  last_90_days: {
    start: Moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD"),
    end: Moment().format("YYYY-MM-DD"),
    months: 3,
    custom: false,
  },
  last_12_months: {
    start: Moment(`${year}-${month}-01`, "YYYY-MM-DD")
      .subtract(12, "months")
      .format("YYYY-MM-DD"),
    end: Moment().format("YYYY-MM-DD"),
    months: 12,
  },
  current_year: {
    start: `${year}-01-01`,
    end: moment.format("YYYY-MM-DD"),
  },
  last_year: {
    start: Moment(`${year}-${month}-01`, "YYYY-MM-DD")
      .subtract(1, "years")
      .format("YYYY-MM-DD"),
    end: Moment(`${year}-${month}-${endingDate}`, "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    ),
    months: 12,
    custom: false,
  },
  custom_range: {
    start: null,
    end: null,
  },
};
