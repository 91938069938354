import { claimCenter } from "@/Platform/ClaimCenter/Store/index.js";
import { team } from "@/Platform/TeamMembers/Store/index.js";
import { adminPanel } from "@/Platform/AdminPanel/Store/index.js";
import { carrierCredentials } from "@/Platform/CarrierCredentials/Store/index.js";
import { filters } from "@/Global/Components/KitForUI/FilterForm/Store/index.js";

export const platformStore = {
  state: {
    toggle: true,
    monthly: true,
    calendarToggle: false,
    filterToggle: false,
    filterSettings: false,
    loading: false,
    cities: null,
    postalCodes: null,
    claimCenter: { ...claimCenter.state },
    team: { ...team.state },
    carrierCredentials: { ...carrierCredentials.state },
    adminPanel: { ...adminPanel.state },
    filters: { ...filters.state },
  },
  mutations: {
    ...claimCenter.mutations,
    ...team.mutations,
    ...carrierCredentials.mutations,
    ...adminPanel.mutations,
    ...filters.mutations,
    SET_TIMELINE(state) {
      state.monthly = !state.monthly;
    },
    TOGGLE_PER_CARRIER(state) {
      state.toggle = !state.toggle;
    },
    SWITCH_CALENDAR_TOGGLE(state) {
      state.calendarToggle = !state.calendarToggle;
      state.filterToggle = false;
    },
    SWITCH_FILTER_TOGGLE(state) {
      state.filterToggle = !state.filterToggle;
      state.calendarToggle = false;
    },
    CLOSE_CALENDAR(state) {
      state.calendarToggle = false;
    },
    CLOSE_FILTER(state) {
      state.filterToggle = false;
    },
    SWITCH_FILTER_SETTINGS(state) {
      state.filterSettings = !state.filterSettings;
    },
    START_LOADING(state) {
      state.loading = true;
    },
    FINISH_LOADING(state) {
      state.loading = false;
    },
    CLEAR_PLATFORM(state) {
      state.toggle = true;
      state.monthly = true;
      state.calendarToggle = false;
      state.filterToggle = false;
      state.filterSettings = false;
      state.loading = false;
      state.cities = null;
      state.postalCodes = null;
      state.filters = {
        carrier: [],
        country_code_receiver: [],
        country_code_sender: [],
        state: [],
        reason_refund: [],
        service_level: [],
        stage: [],
        owners: [],
      };
      state.claimCenter = {
        activeView: "Overview",
        claimToggle: false,
        activeFilterCount: 0,
        activeDateRange: null,
        activeFilters: {},
        filtered: false,
        columnToggle: false,
        columns: null,
        dateRange: {
          month: null,
          week: null,
        },
        claimedBy: "All",
        overview: {
          generalData: null,
          lineChartData: {
            month: {
              total: null,
              individual: null,
            },
            week: {
              total: null,
              individual: null,
            },
          },
          doughnutData: { table: null },
        },
        packageInformation: {
          active: false,
          pageNumber: 1,
          apiConfig: null,
          existingInvoices: null,
          carriers: [],
          packages: [],
          total: 0,
        },
        myClaims: {
          tableData: null,
          typeOfData: null,
        },
      };
      state.team = {
        members: null,
      };
      state.carrierCredentials = {
        carrier: null,
      };
      state.adminPanel = {
        companies: [],
        credentials: null,
        documents: null,
        companyMembers: null,
        users: null,
        loxInvoices: null,
        generalForm: null,
        caseStudies: null,
        invoiceDetails: null,
        apiConfig: null,
      };
    },
    SET_CITIES(state, payload) {
      state.cities = payload.cities;
    },
    SET_POSTAL_CODES(state, payload) {
      state.postalCodes = payload.codes;
    },
  },
  getters: {
    ...claimCenter.getters,
    ...team.getters,
    ...carrierCredentials.getters,
    ...adminPanel.getters,
    ...filters.getters,
    monthTimeline(state) {
      return state.monthly;
    },
    carrierToggle(state) {
      return state.toggle;
    },
    calendarToggle(state) {
      return state.calendarToggle;
    },
    filterToggle(state) {
      return state.filterToggle;
    },
    filterSettings(state) {
      return state.filterSettings;
    },
    loading(state) {
      return state.loading;
    },
    citiesInCountry(state) {
      return state.cities;
    },
    postalCodesInCity(state) {
      return state.postalCodes;
    },
  },
  actions: {
    ...claimCenter.actions,
    ...team.actions,
    ...carrierCredentials.actions,
    ...adminPanel.actions,
    ...filters.actions,
    setTimeline({ commit }) {
      commit("SET_TIMELINE");
    },
    togglePerCarrier({ commit }) {
      commit("TOGGLE_PER_CARRIER");
    },
    switchCalendarToggle({ commit }) {
      commit("SWITCH_CALENDAR_TOGGLE");
    },
    switchFilterToggle({ commit }) {
      commit("SWITCH_FILTER_TOGGLE");
    },
    closeCalendar({ commit }) {
      commit("CLOSE_CALENDAR");
    },
    closeFilter({ commit }) {
      commit("CLOSE_FILTER");
    },
    switchFilterSettings({ commit }) {
      commit("SWITCH_FILTER_SETTINGS");
    },
    startLoading({ commit }) {
      commit("START_LOADING");
    },
    finishLoading({ commit }) {
      commit("FINISH_LOADING");
    },
    clearPlatform({ commit }) {
      commit("CLEAR_PLATFORM");
    },
    setCities({ commit }, payload) {
      commit("SET_CITIES", payload);
    },
    setPostalCodes({ commit }, payload) {
      commit("SET_POSTAL_CODES", payload);
    },
  },
};
